// Components
import Layout from "../../components/Layout/Layout";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import SocialMediaAccounts from "../../components/SocialMediaAccountsSection/SocialMediaAccountsSection";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

// Image
import Computer11 from "../../assets/images/Computer11.webp";

// Style
import "./Contact.scss";

function Contact() {
  return (
    <Layout>
      <ImageBlock image={Computer11} showText={false} />

      <section className="contact-page">
        <SectionTitle title="Faisons connaissance" />

        <SocialMedia
          version="blue"
          email="amdkhissi@gmail.com"
          mobilePhone="+33 6 02 39 08 13"
          gitHub="AmaleDkh"
        />
      </section>
    </Layout>
  );
}

export default Contact;
