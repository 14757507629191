// Components
import Layout from "../../components/Layout/Layout";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import SkillsList from "../../components/SkillsList/SkillsList";
import ContactSection from "../../components/ContactSection/ContactSection";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Introduction from "../../components/Introduction/Introduction";

// Style
import "./Skills.scss";

// Image
import Computer3 from "../../assets/images/Computer3.webp";
import Computer12 from "../../assets/images/Computer12.webp";

function Skills() {
  return (
    <Layout>
      <ImageBlock image={Computer12} showText={false} />

      <div className="skills-page-container">
        <SectionTitle
          title="Mes compétences"
          // title="MES COMPÉTENCES."
          margin="large-margin"
          mobileVersion="version-with-text-align"
          versionWithMarginLeft="version-with-margin-left"
        />
        {/* <Introduction
          text="Au fil de mes projets, j’ai développé des compétences techniques diversifiées, tant en front-end qu'en back-end, afin de concevoir des solutions web performantes."
          versionWithMargin="version-with-margin"
        /> */}
        <SkillsList />
      </div>
      <ContactSection marginBottomVersion="margin-bottom-version" />
    </Layout>
  );
}

export default Skills;
