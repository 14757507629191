// React elements
import { Link } from "react-router-dom";
import { useRef } from "react";

// Context
import { useLanguage } from "../../context/LanguageContext";

// Component
import Button from "../Button/Button";
import SecondButton from "../SecondButton/SecondButton";

// Style
import "./Project.scss";
import "../../assets/styles/Devicon.scss";

// Font Awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

// Devicon icons
import "devicon/devicon.min.css";

function Project({
  title,
  description,
  image,
  technologies,
  source,
  alt,
  projectIndex,
  isVisible,
  position,
}) {
  const projectRef = useRef(null);

  const { language } = useLanguage();

  return (
    <div
      ref={projectRef}
      className={`project-container  ${
        isVisible ? "visible" : "hidden"
      } ${position}`}
      style={{ transitionDelay: `${projectIndex * 0.2}` }}
    >
      <img src={image} alt={alt} loading="lazy" />

      <div className="project-container__information">
        <p className="project-container__information__title">
          {language === "FR" ? title.french : title.english}
        </p>
        <div className="project-container__information__discover-option">
          <p className="project-container__information__discover-option__text">
            Découvrir le projet
          </p>
        </div>

        {/* <Button text="Découvrir le projet" link={`/project/${projectRef.id}`} /> */}
        {/* <p className="project-container__information__description">
          {language === "FR" ? description.french : description.english}
        </p> */}

        {/* <div className="project-container__information__icons">
          <div className="project-container__information__icons__technologies">
            {technologies &&
              technologies.map((tech, techIndex) => (
                <span
                  key={techIndex}
                  className={` devicon ${tech.icon} ${tech.size} devicon-small-version `}
                  title={tech.technology}
                ></span>
              ))}
          </div>

          <Link
            to={source}
            className={`social-media-container__link`}
            target="_blank"
            aria-label="Ouvrir mon GitHub"
          >
            <FontAwesomeIcon
              icon={faGithub}
              className={`social-media-container__link__icon `}
            />
            <span className="sr-only">GitHub</span>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default Project;
