// Components
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";

// Data
import projectsList from "../../assets/data/projectsList.json";

// Font Awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

import "./Project.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import SecondTitleForSection from "../../components/SecondTitleForSection/SecondTitleForSection";

function Project({ title }) {
  const { id } = useParams();

  const project = projectsList.find((project) => project.id === id);
  console.log(project);

  return (
    <Layout>
      <section className="project-section">
        <SectionTitle
          title={project.title.french}
          margin="large-margin"
          mobileVersion="version-with-text-align"
        />

        <div className="project-section__description-block">
          <SecondTitleForSection title="Présentation" />
          <p className="project-section__description">
            {project.description.french}
          </p>
        </div>

        <div className="project-section__technologies-block">
          <SecondTitleForSection title="Technologies utilisées" />
          <div className="project-container__information__icons">
            <div className="project-container__information__icons__technologies">
              {project.technologies.map((tech, techIndex) => (
                <span
                  key={techIndex}
                  className={` devicon ${tech.icon} ${tech.size} devicon-small-version `}
                  title={tech.technology}
                ></span>
              ))}
            </div>
          </div>

          {/* <Link
          to={source}
          className={`social-media-container__link`}
          target="_blank"
          aria-label="Ouvrir mon GitHub"
        >
          <FontAwesomeIcon
            icon={faGithub}
            className={`social-media-container__link__icon `}
          />
          <span className="sr-only">GitHub</span>
        </Link> */}
        </div>
        <img src={project.image} className="project-section__image" />
      </section>
    </Layout>
  );
}

export default Project;
