// React element
import { useState } from "react";
import { Link } from "react-router-dom";

// Components
import Layout from "../../components/Layout/Layout";
import Project from "../../components/Project/Project";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import ContactSection from "../../components/ContactSection/ContactSection";
import Introduction from "../../components/Introduction/Introduction";

// Data
import projectsList from "../../assets/data/projectsList.json";

// Style
import "./Projects.scss";

// Images
import Computer2 from "../../assets/images/Computer2.webp";

function Projects() {
  const [index, setIndex] = useState(1);

  const showPreviousProject = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? projectsList.length - 1 : prevIndex - 1
    );
  };

  const showNextProject = () => {
    setIndex((nextIndex) =>
      nextIndex === projectsList.length - 1 ? 0 : nextIndex + 1
    );
  };

  return (
    <Layout>
      <ImageBlock image={Computer2} showText={false} />
      <div className="projects-page-container">
        <SectionTitle
          title="Un aperçu de mes projets"
          margin="large-margin"
          mobileVersion="version-with-text-align"
          versionWithMarginLeft="version-with-margin-left"
        />
        {/* <Introduction
          text="Chaque projet m'a permis d'apprendre et d'affiner mes compétences en développement web, avec pour objectif de toujours proposer des solutions performantes et adaptées."
          versionWithMargin="version-with-margin"
        /> */}

        <div className="projects__container projects__container--mobile-version projects__container--page-version">
          {/* {projectsList.slice(0, 3).map((project, projectIndex) => (
            <Link
              to={`project/${projectsList[index].id}`}
              className="projects__container--desktop-version__link"
            >
              <Project
                key={projectIndex}
                title={project.title}
                description={project.description}
                details={project.details}
                technologies={project.technologies}
                source={project.source}
                image={project.image}
                projectIndex={projectIndex}
                position={
                  projectIndex % 2 === 0 ? "project--right" : "project--left"
                }
              />
            </Link>
          ))} */}
          {/* <div onClick={showPreviousProject}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 arrow-left"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </div>
          <Link
            to={`project/${projectsList[index].id}`}
            className="projects__container--desktop-version__link"
          >
            <div>
              <Project
                key={index}
                title={projectsList[index].title}
                description={projectsList[index].description}
                details={projectsList[index].details}
                technologies={projectsList[index].technologies}
                source={projectsList[index].source}
                alt={`Couverture du projet : ${projectsList[index].title}`}
                image={projectsList[index].image}
              />
            </div>
          </Link>

          <div onClick={showNextProject}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 arrow-right"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
              />
            </svg>
          </div> */}
        </div>

        <div className="projects__container page-projects projects__container--desktop-version">
          {projectsList.slice(0, 3).map((project, projectIndex) => (
            <Link
              to={`/project/${project.id}`}
              className="projects__container--desktop-version__link"
            >
              <Project
                key={projectIndex}
                title={project.title}
                description={project.description}
                details={project.details}
                technologies={project.technologies}
                source={project.source}
                image={project.image}
                projectIndex={projectIndex}
                position={
                  projectIndex % 2 === 0 ? "project--right" : "project--left"
                }
              />
            </Link>
          ))}
        </div>
      </div>
      <ContactSection marginBottomVersion="margin-bottom-version" />
    </Layout>
  );
}

export default Projects;
