// Components
import Layout from "../../components/Layout/Layout";
import Presentation from "../../components/Presentation/Presentation";
import SocialMediaAccountsSection from "../../components/SocialMediaAccountsSection/SocialMediaAccountsSection";
import ProjectsList from "../../components/ProjectsList/ProjectsList";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import ImageAndTextBlock from "../../components/ImageAndTextBlock/ImageAndTextBlock";
import ServicesList from "../../components/ServicesList/ServicesList";
import ContactSection from "../../components/ContactSection/ContactSection";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Reviews from "../../components/Reviews/Reviews";
import TextSection from "../../components/TextSection/TextSection";
import Button from "../../components/Button/Button";
import ValuesList from "../../components/ValuesList/ValuesList";
import ThreeBlocksSection from "../../components/ThreeBlocksSection/ThreeBlocksSection";

// Style
import "../../assets/styles/Global.scss";
import "./../../assets/styles/Fonts.scss";
import "../Home/Home.scss";

// Images
import Computer1 from "../../assets/images/Computer.webp";
import Me1 from "../../assets/images/Me1.jpeg";
import Me3 from "../../assets/images/Me3.jpeg";
import Me5 from "../../assets/images/Me5.jpeg";

function Home() {
  return (
    <Layout>
      <Presentation />

      {/* <ThreeBlocksSection
        title="UNE APPROCHE BASÉE SUR L'ÉCOUTE, L'ENGAGEMENT ET LA QUALITÉ.s"
        firstTitle="Collaboration"
        secondTitle="Engagement"
        thirdTitle="Qualité"
        firstParagraph="Chaque projet est une aventure partagée où l'écoute active, l’échange et la compréhension mutuelle sont la clé d’un résultat sur mesure."
        secondParagraph="Un investissement totaldans chaque projet pour offrir des solutions efficaces et adaptées à vos besoins spécifiques."
        thirdParagraph="La qualité est primordiale dans chaque réalisation, pour garantir des résultats durables et alignés avec vos objectifs."
      /> */}

      <div className="values-list-section">
        <SectionTitle
          title={`Une approche basée sur</br>l'écoute, l'engagement et la qualité`}
          // title={`UNE APPROCHE BASÉE SUR </br>L'ÉCOUTE, L'ENGAGEMENT ET LA QUALITÉ.`}
          mobileVersion="version-with-text-align"
        />
        <ValuesList />
      </div>

      <ImageAndTextBlock
        text={`Que vous ayez besoin d'un site codé sur mesure ou d'une solution no-code, 
        je m'adapte et vous propose  <span class="words-with-font-weight">des solutions personnalisées et évolutives</span>. <br></br>
        Mon objectif est de vous fournir <span class="words-with-font-weight">un site performant et facile à prendre en main</span>, 
        tout en restant aligné avec vos objectifs.`}
        image={Me5}
        sectionTitle={`Une solution sur mesure <br>pour chaque projet`}
        // sectionTitle="UNE SOLUTION SUR MESURE POUR CHAQUE PROJET."
        // subtitle="Collaboration, engagement & qualité"
        className="image-and-text-block--background-color-version"
        textPadding="image-and-text-block__margin-left"
        versionWithSubtitle={true}
        alt="Amale assise à la BNF et en train de travailler"
      />

      {/* <TextSection
        sectionTitle="UNE APPROCHE SUR MESURE POUR CHAQUE PROJET."
        text={`Que vous ayez besoin d'un site codé sur mesure ou d'une solution no-code, 
          je m'adapte et vous propose  <span class="words-with-font-weight">des solutions personnalisées et évolutives</span>. <br></br>
          Mon objectif est de vous fournir <span class="words-with-font-weight">un site performant et facile à prendre en main</span>, 
          tout en restant aligné avec vos objectifs.`}
        imageSrc={Me3}
      /> */}

      {/* <ImageBlock /> */}

      {/* <ImageAndTextBlock
        text={`Ma démarche repose sur <span class="words-with-font-weight">l'écoute et la collaboration</span>, 
        afin de créer des solutions web sur mesure. <br></br> Je privilégie <span class="words-with-font-weight">l'engagement et la qualité</span> 
        pour concevoir <span class="words-with-font-weight">des sites durables, évolutifs et adaptés à vos besoins</span>.`}
        image={Me5}
        sectionTitle="MON APPROCHE."
        subtitle="Collaboration, engagement & qualité"
        className="image-and-text-block--background-color-version"
        textPadding="image-and-text-block__margin-left"
        versionWithSubtitle={true}
        alt="Amale assise à la BNF et en train de travailler"
      /> */}

      <div className="services-list">
        <div className="home-page__title-section">
          <SectionTitle
            title="Les solutions proposés"
            className=""
            margin="large-margin"
            mobileVersion="version-with-text-align"
            versionWithMarginTop="version-with-margin-top"
          />
        </div>

        <ServicesList />

        <div className="homepage-button">
          <Button link="/services" text="Découvrez les solutions sur mesure" />
        </div>
      </div>

      <Reviews />

      <ProjectsList />

      <ImageAndTextBlock
        text={`Après <span class="words-with-font-weight">7 ans d'expérience en communication</span>, je me suis lancée dans le développement web, où j'allie <span class="words-with-font-weight">organisation et professionnalisme</span> pour créer <span class="words-with-font-weight">des solutions à la fois élégantes et performantes</span>.
        <br></br> Passionnée par <span class="words-with-font-weight">la littérature</span> depuis mon plus jeune âge, j'aime concevoir des solutions web qui allient <span class="words-with-font-weight">narration et performance</span>, tout en répondant aux attentes techniques et esthétiques.`}
        image={Me1}
        alt="Photo d'Amale à la terrasse d'un café en hiver"
        className="row-reverse-version"
        sectionTitle="Mon histoire"
        textPadding="image-and-text-block__margin-right"
        versionWithButton={true}
        textButton="À propos de moi"
        linkButton="/about"
        versionWithSubtitle={false}
      />

      <ContactSection marginBottomVersion="margin-bottom-version" />
    </Layout>
  );
}

export default Home;
