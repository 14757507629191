// Component
import ExperienceItem from "../ExperienceItem/ExperienceItem";
import SecondTitleForSection from "../SecondTitleForSection/SecondTitleForSection";

// Data
import experiencesList from "../../assets/data/experiencesList.json";

// Style
import "./Experiences.scss";

// Image
import Me6 from "../../assets/images/Me6.jpeg";

function Experiences() {
  return (
    <div className="experiences-list">
      {/* <div className="experiences-list__image-container"> */}
      <img
        src={Me6}
        alt="Une personne de dos qui travaille sur un bureau"
        className="experiences-list__image-container__image image-with-opacity image-with-border-radius"
      />
      {/* </div> */}
      <div className="experiences-list__texts">
        <SecondTitleForSection title="Mes expériences" />
        <div className="experiences-list__texts__block">
          {experiencesList.map((experienceItem, experienceItemIndex) => (
            <ExperienceItem
              key={experienceItemIndex}
              year={experienceItem.year}
              description={experienceItem.description}
              experience={experienceItem.experience}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Experiences;
