// React element
import { useLocation } from "react-router-dom";

import SectionTitle from "../SectionTitle/SectionTitle";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import SecondTitleForSection from "../SecondTitleForSection/SecondTitleForSection";

import "./TextSection.scss";

function TextSection({ text, sectionTitle, textSectionColorVersion }) {
  const location = useLocation();

  return (
    <section className="text-section">
      {/* <HorizontalLine className="horizontal-line__centered-black-version horizontal-line__margin-bottom-version " /> */}
      {/* <img src={imageSrc} alt={imageAlt} />
      <div className="text-section__content ">
        {location.pathname === "/" ? (
          <SectionTitle
            title={sectionTitle}
            className=""
            margin="large-margin"
            colorVersion="white-version"
          />
        ) : (
          <SecondTitleForSection title={sectionTitle} />
        )}
        <p
          className="text-section__content__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div> */}
      {/* <HorizontalLine className="horizontal-line__centered-black-version" /> */}

      <div className={`text-section__content ${textSectionColorVersion}`}>
        {location.pathname === "/" ? (
          <SectionTitle
            title={sectionTitle}
            className=""
            margin="large-margin"
            colorVersion="white-version"
          />
        ) : (
          <SecondTitleForSection title={sectionTitle} />
        )}
        <p
          className="text-section__content__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </section>
  );
}

export default TextSection;
